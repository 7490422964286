/* eslint-disable class-methods-use-this */
import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import createAuthenticatedHttpLink from '@onemedical/graphql-auth';

const maxRetries = 100;
let numRetries = 0;

const GetZendeskToken = gql`
  query ZendeskToken {
    zendeskToken
  }
`;
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createAuthenticatedHttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL }),
});

class PendoHelper {
  constructor() {
    this.enabled = !!process.env.REACT_APP_PENDO_API_KEY;
  }

  initialize(profile) {
    const testAccount = this.automatedTestingAccount(profile);
    if (this.enabled && !testAccount) {
      this.injectScript();
      this.initPendo(profile);
    }
  }

  injectScript() {
    window.pendoFinishedLoading = false;
    const script = `
      (function(apiKey) {
        (function(p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
          for (w = 0, x = v.length; w < x; ++w)(function(m) {
            o[m] = o[m] || function() {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
          })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = 'https://content.pendo.ui.1life.com/agent/static/' + apiKey + '/pendo.js';
          y.onload = function() {
            window.pendoFinishedLoading = true;
          };
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
      })('${process.env.REACT_APP_PENDO_API_KEY}');
    `;
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.innerHTML = script;
    document.head.appendChild(el);
  }

  allRoles(profile) {
    let roles = [];

    if (profile.preferredRole && profile.preferredRole.name) {
      roles.push(profile.preferredRole.name.toLowerCase());
    }

    if (profile.nonPreferredRoles.length > 0) {
      const other_roles = profile.nonPreferredRoles.map((r) => r.name.toLowerCase());
      roles = roles.concat(other_roles);
    }
    const vmtRoleIndex = roles.indexOf('virtual medical team / vmt');
    if (vmtRoleIndex !== -1) {
      roles.splice(vmtRoleIndex, 1, 'clinical support team');
    }
    return roles.map((r) => r.split(' ').join('_'));
  }

  automatedTestingAccount(profile) {
    const lastNameStaging = profile.lastName.includes('staging');
    const hasOneMedicalEmail = profile.email.includes('onemedical.com');
    return lastNameStaging && hasOneMedicalEmail;
  }

  initPendo(profile) {
    client
      .query({
        query: GetZendeskToken,
      })
      .then((token) => {
        window.zESettings = {
          webWidget: {
            authenticate: {
              jwtFn(callback) {
                callback(token.data.zendeskToken);
              },
            },
          },
        };
        this.initZendesk();
      });

    const roles = this.allRoles(profile);
    window.pendo.initialize({
      visitor: {
        id: profile.id,
        email: profile.email,
        full_name: profile.displayName,
        role: roles,
        tags: this.parseTags(roles),
      },
      account: {
        id: 'Internal Users',
      },
      // ensure pendo doesn't attempt to send any PII text from the DOM.
      // we have this enabled at the subscription level but this makes
      // it explicitly clear for developers.
      excludeAllText: true,
    });
  }

  initZendesk() {
    setTimeout(() => {
      if (window.pendoFinishedLoading) {
        const zendeskEl = document.createElement('script');
        zendeskEl.id = 'ze-snippet';
        zendeskEl.src =
          'https://static.zdassets.com/ekr/snippet.js?key=36992a6c-1f30-43f4-b3f3-7b11c6293b28';
        document.head.appendChild(zendeskEl);
      } else if (numRetries < maxRetries) {
        numRetries += 1;
        this.initZendesk();
      }
    }, 100);
  }

  parseTags(roles) {
    if (roles.includes('provider') && roles.includes('clinical_support_team')) {
      return ['vmt provider'];
    }
    if (roles.includes('provider')) {
      return ['in-office provider'];
    }
    if (roles.includes('phlebotomist')) {
      return ['phleb'];
    }
    if (roles.includes('admin')) {
      return ['admin'];
    }
    return [];
  }
}

PendoHelper.fragments = {
  profile: gql`
    fragment PendoHelperProfile on InternalUser {
      displayName
      lastName
      email
      nonPreferredRoles {
        id
        name
      }
      preferredRole {
        id
        name
      }
    }
  `,
};

export default PendoHelper;
