import { useEffect, useMemo } from 'react';
import { setup } from '@onemedical/command-palette';
import useElasticsearch from '@onemedical/elasticsearch-hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { PatientElasticsearchResult } from './NavBar.types';
import { patientQuery } from './PatientSearchBox';

const chartUrl = process.env.REACT_APP_CLINICAL_UX_URL;

const CommandPalette = () => {
  const { commandPaletteInitialExperiment } = useFlags();

  const [search, { results }] = useElasticsearch<PatientElasticsearchResult>(patientQuery, {
    url: process.env.REACT_APP_ELASTICSEARCH_URL!,
    prefix: process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX,
    index: 'patients',
    size: 10,
    lazy: true,
  });

  const addResultsToSearch = useMemo(() => {
    if (!commandPaletteInitialExperiment) {
      return () => {};
    }
    const { asyncActionBuilder } = setup({
      params: {
        adminUrl: '',
        onelifeUrl: process.env.REACT_APP_ONELIFE_URL!,
      },
    });

    return asyncActionBuilder({
      action: {
        id: 'patients',
        title: 'Search for patients',
      },
      debounce: 200,
      async onSearch(query) {
        search({ variables: { q: query } });
        return [];
      },
    });
  }, [commandPaletteInitialExperiment, search]);

  useEffect(() => {
    if (!results) {
      return;
    }
    addResultsToSearch(
      results?.map((result) => {
        const { nickname, first_name, id, display_dob, last_name } = result;
        const displayFirstName = nickname ? `${nickname} (${first_name})` : first_name;

        return {
          id: result.id.toString(),
          title: `${displayFirstName} ${last_name} (${display_dob})`,
          url: `${chartUrl}/#/patients/${id}/chart/summaries/new`,
        };
      }),
    );
  }, [results, addResultsToSearch]);

  return null;
};

export default CommandPalette;
